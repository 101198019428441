<template>
  <div>
    <section class="columns is-tablet" v-if="loaded && totalTransaction">
      <div class="column is-4-tablet">
        <div class="card">
          <div class="card-content">
            <p class="is-size-4 has-text-weight-bold">Summary</p>

            <div class="is-divider"></div>

            <div class="is-flex is-justify-content-space-between has-text-success">
              <span class="is-flex"><b-icon class="mr-2" icon="currency-usd"/> {{ $t('finance.income') }}</span>
              <span class="has-text-weight-bold">{{ $currency(totalTransaction.total_pemasukan) }}</span>
            </div>

            <div class="is-divider"></div>

            <div class="is-flex is-justify-content-space-between has-text-danger">
              <span class="is-flex"><b-icon class="mr-2" icon="cart-outline"/> {{ $t('finance.expenses') }}</span>
              <span class="has-text-weight-bold">
                {{ $currency(totalTransaction.total_pengeluaran) }}</span>
            </div>

            <div class="is-divider"></div>

            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <p class="has-text-weight-bold">{{ $t('finance.balance') }}</p>
              <span class="is-size-4 has-text-weight-bold">
                {{ $currency(totalTransaction.total_pemasukan - totalTransaction.total_pengeluaran) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4-tablet">
        <div class="card">
          <div class="card-content">
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-4 has-text-weight-bold">{{ $t('finance.income') }}</p>
            </div>

            <div class="is-divider"></div>

            <div v-if="incomeTransaction">
              <apexchart type="donut"
                         height="350"
                         :options="incomeOptions"
                         :series="incomeData"/>

              <div class="is-divider"></div>

              <div class="is-flex is-justify-content-space-between" v-for="category in incomeTransaction"
                   :key="category.category_transaction_id">
                <span>{{ category.category_transaction_name }}</span>
                <span class="has-text-success has-text-weight-bold">{{ $currency(category.nominal) }}</span>
              </div>
            </div>
            <div v-else>{{ $t('no') }} Data</div>
          </div>
        </div>
      </div>
      <div class="column is-4-tablet">
        <div class="card">
          <div class="card-content">
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-4 has-text-weight-bold">{{ $t('finance.expenses') }}</p>
            </div>

            <div class="is-divider"></div>

            <div v-if="expensesTransaction">
              <apexchart type="donut"
                         height="350"
                         :options="expensesOptions"
                         :series="expensesData"/>

              <div class="is-divider"></div>

              <div class="is-flex is-justify-content-space-between" v-for="category in expensesTransaction"
                   :key="category.category_transaction_id">
                <span>{{ category.category_transaction_name }}</span>
                <span class="has-text-danger has-text-weight-bold">{{ $currency(category.nominal) }}</span>
              </div>
            </div>
            <div v-else>{{ $t('no') }} Data</div>

          </div>
        </div>
      </div>
    </section>
    <div class="has-text-centered my-4" v-else-if="loaded">{{ $t('no') }} Data</div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'FinanceDashboard',
  components: {
    apexchart: VueApexCharts,
  },
  data: () => {
    return {
      loaded: false,
      incomeOptions: {
        chart: {
          type: 'donut',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
          }
        }],
        legend: {
          show: true,
          position: 'bottom'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontFamily: 'Nunito Sans, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#000', '#000', '#000', '#000', '#000', '#000', '#000']
          },
          dropShadow: {
            enabled: false,
          }
        },

        plotOptions: {
          pie: {
            startAngle: 0,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: .8,
            dataLabels: {
              offset: 40,
              minAngleToShowLabel: 0
            },
            donut: {
              size: '70%',
              background: 'transparent',
            },
          }
        },
      },
      expensesOptions: {
        chart: {
          type: 'donut',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
          }
        }],
        legend: {
          show: true,
          position: 'bottom'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontFamily: 'Nunito Sans, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#000', '#000', '#000', '#000', '#000', '#000', '#000']
          },
          dropShadow: {
            enabled: false,
          }
        },

        plotOptions: {
          pie: {
            startAngle: 0,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: .8,
            dataLabels: {
              offset: 40,
              minAngleToShowLabel: 0
            },
            donut: {
              size: '70%',
              background: 'transparent',
            },
          }
        },
      },
    }
  },
  computed: {
    ...mapState('farm', [
      'selectedFarm'
    ]),

    ...mapState('finance', [
      'selectedAccount',
      'totalTransaction',
    ]),

    ...mapGetters('finance', [
      'incomeTransaction',
      'expensesTransaction'
    ]),

    incomeData() {
      if (this.incomeTransaction) return this.incomeTransaction.map(e => e.nominal)
      return []
    },

    expensesData() {
      if (this.expensesTransaction) return this.expensesTransaction.map(e => e.nominal)
      return []
    },
  },
  watch: {
    'selectedAccount.account_id'() {
      this.loadData()
    }
  },
  created() {
    if (this.selectedAccount.account_id) this.loadData()
  },
  methods: {
    ...mapActions('finance', [
      'getAccount',
      'getTotalTransaction',
      'getGroupTransaction',
    ]),

    loadData() {
      this.$loading()
      let promises = [
        new Promise((resolve, reject) => {
          this.getTotalTransaction().then(res => resolve(res)).catch(err => reject(err))
        }),
        new Promise((resolve, reject) => {
          this.getGroupTransaction({type: 'in'}).then(res => resolve(res)).catch(err => reject(err))
        }),
        new Promise((resolve, reject) => {
          this.getGroupTransaction({type: 'out'}).then(res => resolve(res)).catch(err => reject(err))
        }),
      ]

      Promise.all(promises).finally(() => {
        if (this.incomeTransaction) this.incomeOptions.labels = this.incomeTransaction.map(e => e.category_transaction_name)
        if (this.expensesTransaction) this.expensesOptions.labels = this.expensesTransaction.map(e => e.category_transaction_name)
        this.$loading(false)
        this.loaded = true
      }).catch(err => {
        console.log(err)
      })
    },
  },
}
</script>
